<template>
  <div>
    <v-row>
      <v-col cols="12" lg="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="3" md="3" sm="12">
                <v-select :items="UserItem" label="เลือกสมาชิก" v-model="UserId" ref="UserItem"></v-select>
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="12">
                <v-text-field
                  label="ส่วนแบ่ง เปอร์เซ็น"
                  type="number"
                  v-model="Percent"
                  ref="Percent"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="1" md="1" sm="12">
                <v-btn width="100%" color="info" @click="Insert()">เพิ่ม</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="12">
        <v-card>
          <v-simple-table height="200px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">ระดับ</th>
                  <th class="text-left">ชื่อ</th>
                  <th class="text-left">ส่วนแบ่ง %</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in Items" :key="i.RubberDetailId">
                  <td width="120">{{i.UserLevelText}}</td>
                  <td width="300">{{i.UserName}}</td>
                  <td>{{i.Percent}}</td>
                  <td>
                    <v-btn fab dark small color="red" @click="Delete(i)">
                      <v-icon dark>fa fa-trash-alt</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="12" lg="12">
        <v-row>
          <v-col cols="12" lg="1" md="1" sm="12">
            <v-btn width="100%" color="success" @click="Accept()">บันทึก</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      UserItem: [],
      UserId: "",
      Percent: 0,
      Items: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.SelectMember();
      this.Select();
    });
  },
  methods: {
    SelectMember() {
      axios.get("/api/v1/select/SelectMemberRubber/user").then(res => {
        if (res.status == 200) {
          this.UserItem = res.data;
        }
      });
    },
    Accept() {
      axios
        .get(
          "/api/v1/update/Accept/rubberdetail?RubberId=" +
            this.$route.query.RubberId
        )
        .then(res => {
          if (res.status == 200) {
            this.$router.push("/Rubber");
          }
        });
    },
    Select() {
      axios
        .get(
          "/api/v1/select/Select/rubberdetail?RubberId=" +
            this.$route.query.RubberId
        )
        .then(res => {
          if (res.status == 200) {
            this.Items = res.data;
          }
        });
    },
    Insert() {
      if (this.UserId == "") {
        this.$refs.UserItem.focus();
        return;
      }
      if (this.Percent < 1) {
        this.$refs.Percent.focus();
        return;
      }
      var data = {
        UserId: this.UserId,
        Percent: this.Percent,
        RubberId: this.$route.query.RubberId
      };
      axios.post("/api/v1/insert/insert/RubberDetail", data).then(res => {
        if (res.status == 200) {
          this.SelectMember();
          this.Select();
        }
      });
    },
    Delete(v) {
      axios
        .delete(
          "/api/v1/delete/delete/RubberDetail?RubberDetailId=" +
            v.RubberDetailId
        )
        .then(res => {
          if (res.status == 200) {
            this.SelectMember();
            this.Select();
          }
        });
    }
  }
};
</script>

<style>
</style>